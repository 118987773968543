.boganbarry {
  &__wrapper {
    background-color: #4e6f8e;
    height: 200vh;
    overflow: hidden;
  }

  &__container {
    height: 100%;
  }

  &__title {
    color: #eeeeee;
    font-size: 2rem;
  }
  &__subtitle {
    color: #eeeeee;
    font-size: 0.9rem;
    margin-top: -10px;
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  &__slider {
    position: absolute;
    bottom: 0;
    right: 0;
    background: url('/img/boganbarry/bogabarry-side-scroller.png') right bottom no-repeat;
    background-size: contain;
    height: 300px;
    width: 300px;
  }  
}