.scenes-who {
  &__wrapper {
    height: 100vh;
    background-color: #FFDC00;
    overflow: hidden;
  }

  &__title {
    font-size: 2rem;
    color: white;
    text-align: center;
  }

  @media only screen and (max-width: 600px) {
    &__title {
      font-size: 1.2rem;
    }
  }
}
