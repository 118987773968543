*, *:before, *:after {
    box-sizing: border-box; }

body {
    background-color: #fff;
    padding: 0;
    margin: 0;
    color: $color-text;
    font: 1em tahoma, arial, sans-serif;
    line-height: 1.8; }

h1, h2, h3, h4, h5, h6 {
    font-weight: 900;
    color: $color-primary; }

.active > a {
    font-weight: bold; }
