.scenes-logo {
  &__wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &__canvas {
    position: fixed;
  }

  &__content {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &__image-container {
    height: 200px;
    width: 100%;
    max-width: 800px;
    margin: auto;

    display: flex;
    align-items: center;
  }

  &__image {
    max-width: 100%;
  max-height: auto;
  }
  
}

