// Colors

$color-text:            #000;
$color-primary:         #000;
$color-secondary:       #000;
$color-tertiary:        #000;

// Grid

$screen-tiny:           480px;
$screen-small:          768px;
$screen-medium:         992px;
$screen-large:          1200px;

$grid-container-small:  750px;
$grid-container-medium: 970px;
$grid-container-large:  1170px;

$grid-columns:          12;
$grid-gutter-width:     30px;
$grid-gutter:           $grid-gutter-width / 2;

$grid-include-pull:     false;
$grid-include-push:     false;
$grid-include-offset:   false;
